<template>
    <div class="Profile-wrapper">
        <v-container v-if="$auth.ready()">
            <v-row justify="center">
                <v-col class='text-center' cols="2">
                    <router-link :to="{name: 'user.setting'}" class="linked">
                        <app-icon class="top-icon" icon="cog"/>
                    </router-link>
                </v-col>
                <v-spacer/>
                <v-col class='text-center' cols="2">
                    <router-link :to="{name: 'user.own.messages'}" class="linked">
                        <v-badge
                            v-if="isActiveMessages"
                            :content="unreadMessagesStr"
                            color="#FE4811"
                            bottom
                            offset-x="14"
                            offset-y="16"
                        >
                            <app-icon icon="telegram" :active="isActiveMessages"/>
                        </v-badge>
                        <app-icon v-else icon="telegram" />
                    </router-link>
                </v-col>
            </v-row>
            <v-row justify="center">
                <profile-image
                    :img="userData.data.profileImage"
                    :username="user.name"
                    size="136"
                    :has-not-watched-stories="user.hasNotWatchedStories"
                    :is-exist-story="user.isExistStory"
                    class="mt-6"
                    :id="user.id"
                    :editable="user.isExistStory"
                    @click="handleClickProfile"
                />
            </v-row>
            <v-row justify="center">
                <div class="name d-flex justify-center align-center">
                    <span>
                        {{ user.name }}
                    </span>
                    <verified-icon
                        v-if="user.verified"
                        :height="24" :width="24" class="ml-2" />
                </div>
            </v-row>
            <v-row justify="center">
                <v-col cols="4" class="ma-7 mt-4 text-end">
                    <router-link :to="{name: 'user.profile.followers', params: {id: user.id}}"
                                 class="link text-decoration-none">
                        <div class="text-center">
                        <span class="counters">
                           {{ user.followersCount }}
                        </span>
                            <span class="hint">{{ $t('pages.profile.main.followers') }}</span>
                        </div>
                    </router-link>
                </v-col>
                <v-col cols="3" class="ma-7 mt-4">
                    <router-link :to="{name: 'user.profile.following', params: {id: user.id}}"
                                 class="link text-decoration-none">
                        <div class="text-center">
                            <span class="counters"> {{ user.followingCount }} </span>
                            <span class="hint">{{ $t('pages.profile.main.following') }}</span>
                        </div>
                    </router-link>
                </v-col>
            </v-row>
            <v-row no-gutters justify="center">
                <v-col cols="12">
                    <router-link :to="{name: 'user.own.profile.info'}" class="link text-decoration-none">
                        <v-row justify="center" align="center">
                            <v-col cols="2">
                                <app-icon icon="write"/>
                            </v-col>
                            <v-col cols="8">
                                {{ $t('pages.profile.main.info') }}
                            </v-col>
                            <v-col cols="2" class="text-end">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-col>
                        </v-row>
                    </router-link>
                </v-col>
            </v-row>
            <v-row no-gutters justify="center">
                <v-col cols="12">
                    <router-link :to="{name: 'user.setting.creator'}" class="link text-decoration-none">
                        <v-row justify="center" align="center">
                            <v-col cols="2">
                                <app-icon icon="dollar"/>
                            </v-col>
                            <v-col cols="8">
                                {{ $t('pages.profile.main.creator') }}
                            </v-col>
                            <v-col cols="2" class="text-end">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-col>
                        </v-row>
                    </router-link>
                </v-col>
            </v-row>
            <v-row v-if="isPWAInstalled == false && isEventCall == false" no-gutters justify="center">
                <v-col cols="12">
                    <v-row justify="center" align="center">
                        <v-col cols="2">
                            <app-icon icon="write"/>
                        </v-col>
                        <v-col cols="8" @click="openInstallDialog = true">
                            {{ $t('pages.profile.main.get_app') }}
                        </v-col>
                        <v-spacer/>
                        <app-transition>
                            <instruction-popup v-if="openInstallDialog" @yes="confirmInstallApp" @no="openInstallDialog = false">
                            </instruction-popup>
                        </app-transition>
                        <v-col cols="2">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-if="isPWAInstalled == false && isEventCall == true" no-gutters justify="center">
                <v-col cols="12">
                    <v-row justify="center" align="center">
                        <v-col cols="2">
                            <app-icon icon="write"/>
                        </v-col>
                        <v-col cols="8" @click="openInstallDialog = true">
                            {{ $t('pages.profile.main.get_app') }}
                        </v-col>
                        <v-spacer/>
                        <app-transition>
                            <yes-no-dialog v-if="openInstallDialog" @yes="confirmInstallApp" @no="openInstallDialog = false">
                                {{ $t('dialogs.are_you_sure') }}
                            </yes-no-dialog>
                        </app-transition>
                        <v-col cols="2">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters justify="center">
                <v-col cols="12">
                    <v-row justify="center" align="center">
                        <v-col cols="2">
                            <app-icon icon="share" @click="share"/>
                        </v-col>
                        <v-col cols="8">
                            <span ref="userlinkFull" :title="userlinkFull">{{ marketUserText }}</span>
                        </v-col>
                        <v-col cols="2" class="text-end" @click="copyLink">
                            <app-icon icon="copy"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <bottom-bar :active="activeBottomBar" :model="false"></bottom-bar>
        <inform-dialog
            :text="$t('dialogs.not_contents')"
            v-if="this.isNotHaveContents"
            @close="handleCloseInformDialog"
        />
    </div>
</template>

<script>
import BottomBar from "@/components/app/navigation/BottomBar";
import {mapActions, mapMutations, mapState} from 'vuex'
import ProfileImage from "@/components/app/profile/ProfileImage";
import InformDialog from "@/components/app/dialogs/InformDialog";
import VerifiedIcon from "@/components/app/common/VerifiedIcon";
import InstructionPopup from "@/components/app/profile/InstructionPopup";
import YesNoDialog from "@/components/app/setting/YesNoDialog";

import * as clipboardy from 'clipboardy'
import {DOMAIN_NAME, SERVER_PROTOCOL} from "@/configs/constants";

export default {
    name: "Profile.vue",
    data() {
        return {
            isShowEditStory: false,
            isNotHaveContents: false,
            openDialog: null,
            openInstallDialog: null,
            deferredPrompt: null,
            isPWAInstalled: true,
            isEventCall:false
        }
    },
    computed: {
        ...mapState({
            userData: state => state.user.user,
        }),
        user() {
            return this.$auth.user()
        },
        userlink() {
            return '/' + this.user.slug
        },
        userlinkFull() {
            return `${SERVER_PROTOCOL}://${DOMAIN_NAME}${this.userlink}?referral_code=${this.userData.referralCodes[0]?.value}`
        },
        marketUserText() {
            const userText = 'NOODZLY' + this.userlink
            return userText.toUpperCase()
        },
        unreadMessagesStr() {
            return this.unreadMessages > 9 ? '9+' : `${this.unreadMessages}`
        },
        unreadMessages() {
            return this.unread_messages;
        },
        isActiveMessages() {
            return this.unreadMessages > 0
        },
        ...mapState({
            unread_messages: state => state.user.user.data.unread_messages,
            activeBottomBar: state => state.site.activeBottomBar
        })
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions(['getUserStories', 'getReferrals']),
        share() {
            const shareData = {
                title: this.marketUserText,
                text: 'Check my profile',
                url: this.userlinkFull,
            }
            if (typeof navigator.share !== 'undefined')
                navigator.share(shareData)
                    .then(() => {
                        console.log('shared', shareData)
                    })
                    .catch(() => {
                        console.log('reject', shareData)
                    })
        },
        copyLink() {
            clipboardy.write(this.userlinkFull)
            this.showSnackBar(this.$t('dialogs.copied_clipboard'))
        },
        handleCloseEditStory() {
            this.isShowEditStory = false
        },
        handleCloseInformDialog() {
            this.isNotHaveContents = false
        },
        handleClickProfile() {
            if (!this.user.isExistStory) {
                this.isNotHaveContents = true
            }
        },
        
        closeInstallDialog() {
            this.openInstallDialog = null;
        },

        confirmInstallApp() {
            if (this.deferredPrompt) {
                this.deferredPrompt.prompt(); // Show the install prompt
                this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('PWA installation accepted');
                } else {
                    console.log('PWA installation dismissed');
                }
                this.deferredPrompt = null;
                });
            }
            this.closeInstallDialog();
        },
        checkPWAInstallation() {
            if (navigator.standalone) {
                this.isPWAInstalled = true;
            }
            else if (window.matchMedia('(display-mode: standalone)').matches) {
                this.isPWAInstalled = true;
            }
            else {
                console.log("PWA is not installed");
                this.isPWAInstalled = false;
            }
        },
    },
    components: {
        ProfileImage,
        BottomBar,
        InformDialog,
        VerifiedIcon,
        InstructionPopup,
        YesNoDialog
    },
    async mounted() {
        await this.getReferrals();
    },
    created() {
        const userId = this.$auth.user().id
        this.$echo.private(`user.${userId}`).listen('.story.processed', this.getUserStories)
        this.checkPWAInstallation()
        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            this.deferredPrompt = event;
            console.log("beforeinstallprompt fired in created");
            this.isEventCall = true
        });

        this.$mixpanel.identifyUser(this.userData.data.email);
        this.$mixpanel.pageViewed('Account');
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.Profile {
    &-wrapper {
        min-height: 100%;
    }
}

.top-icon {
    font-size: 28px;
}

.profile-image {
    height: 136px;
    width: 136px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: whitesmoke;
    border: 6px solid black;
    object-fit: cover;
}

.image-container {
    height: 148px;
    width: 148px;
    border-radius: 50%;
    padding-left: 6px;
    padding-top: 6px;
}

.story-exist {
    background: $accent-background;
}

.name {
    font-size: 1.5em;
}

.counters {
    font-size: 1.5em;
}

.hint {
    display: block;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.6);
}

.linked {
    text-decoration: none;
}
</style>
